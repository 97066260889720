import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f080fb00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "container mt-5" }
const _hoisted_3 = { class: "columns is-tablet" }
const _hoisted_4 = { class: "column is-half" }
const _hoisted_5 = { class: "column is-half" }
const _hoisted_6 = { class: "action-footer-container" }
const _hoisted_7 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheBankAccountList = _resolveComponent("TheBankAccountList")!
  const _component_TheTransactionList = _resolveComponent("TheTransactionList")!
  const _component_TheDashboardFooter = _resolveComponent("TheDashboardFooter")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_TheBankAccountList, {
              onRefreshTransaction: _ctx.refreshTransaction,
              onAccountSelected: _ctx.accountSelected,
              refreshToggle: _ctx.refreshAccountsToggle,
              account: _ctx.account
            }, null, 8, ["onRefreshTransaction", "onAccountSelected", "refreshToggle", "account"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.account)
              ? (_openBlock(), _createBlock(_component_TheTransactionList, {
                  onRefreshAccounts: _ctx.refreshAccounts,
                  onRefreshTransaction: _ctx.refreshTransaction,
                  refreshToggle: _ctx.refreshTransactionsToggle,
                  account: _ctx.account,
                  key: _ctx.account
                }, null, 8, ["onRefreshAccounts", "onRefreshTransaction", "refreshToggle", "account"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_TheDashboardFooter, {
            account: _ctx.account,
            onRefreshTransaction: _ctx.refreshTransaction,
            onRefreshAccounts: _ctx.refreshAccounts
          }, null, 8, ["account", "onRefreshTransaction", "onRefreshAccounts"])
        ])
      ])
    ])
  ]))
}