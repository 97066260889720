import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-433d869a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "is-size-5 is-flex-grow-1" }
const _hoisted_2 = {
  key: 0,
  class: "account-backend is-size-6 error-msg"
}
const _hoisted_3 = {
  key: 1,
  class: "account-backend is-size-6"
}
const _hoisted_4 = { class: "is-align-items-center is-flex" }
const _hoisted_5 = {
  key: 0,
  class: "is-size-6-mobile is-size-4-tablet account-bal"
}
const _hoisted_6 = {
  key: 1,
  class: "is-size-6-mobile is-size-4-tablet account-bal inactive"
}
const _hoisted_7 = { class: "is-size-6-mobile is-size-5-tablet account-curr" }
const _hoisted_8 = { class: "dropdown-trigger" }
const _hoisted_9 = {
  class: "button is-default button-contextual-menu is-pulled-right is-rounded refresh ml-2",
  "aria-haspopup": "true",
  "aria-controls": "bank-account-menu"
}
const _hoisted_10 = { class: "icon" }
const _hoisted_11 = {
  class: "dropdown-menu",
  id: "bank-account-menu",
  role: "menu"
}
const _hoisted_12 = { class: "dropdown-content" }
const _hoisted_13 = { class: "mr-1" }
const _hoisted_14 = { class: "ml-1 is-small" }
const _hoisted_15 = { class: "mr-1" }
const _hoisted_16 = { class: "ml-1 is-small" }
const _hoisted_17 = { class: "mr-1" }
const _hoisted_18 = { class: "ml-1 is-small" }
const _hoisted_19 = {
  key: 0,
  class: "sub-accounts"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_BankAccountItem = _resolveComponent("BankAccountItem", true)!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["account", { active: _ctx.account?.active }])
  }, [
    _createElementVNode("div", {
      class: "custom-inner-card card px-5 py-2 is-flex",
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.account?.active && _ctx.$emit('accountSelected', _ctx.account)))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "name", {}, () => [
          _createTextVNode("default name")
        ], true),
        (_ctx.isTemporarilyUnavailable)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$gettext("Temporarily unavailable - please refresh")), 1))
          : _createCommentVNode("", true),
        (_ctx.isMultiCurrency && !_ctx.isSub)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.account?.backend), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.account?.active)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.numericFormat(parseFloat(_ctx.account?.bal))), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_6, "-.---,--")),
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.account?.curr || "--"), 1),
        _createElementVNode("span", {
          class: _normalizeClass({ hide: _ctx.isSub || !_ctx.showActions })
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["dropdown", { 'is-active': _ctx.dropDownMenuState }])
          }, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("span", _hoisted_9, [
                _createElementVNode("span", _hoisted_10, [
                  _createVNode(_component_fa_icon, {
                    class: "qrcode-icon",
                    icon: "ellipsis-v"
                  })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("a", {
                  href: "#",
                  class: "dropdown-item is-flex",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (
                    _ctx.$modal.open('QrCodeModal', { accountId: _ctx.account?.id })
                  ))
                }, [
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_fa_icon, {
                      class: "qrcode-icon",
                      icon: "qrcode"
                    })
                  ]),
                  _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$gettext("Qrcode")), 1)
                ]),
                (
                    _ctx.account?.safeWalletRecipient &&
                    !_ctx.$config?.disableReconversion
                  )
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: "#",
                      class: "dropdown-item is-flex",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (
                    _ctx.$modal.open('MoneyTransferModal', {
                      account: _ctx.account,
                      safeWallet: _ctx.account?.safeWalletRecipient,
                      refreshTransaction: _ctx.refreshTransaction,
                      refreshAccounts: _ctx.refreshAccounts,
                    })
                  ))
                    }, [
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_fa_icon, {
                          class: "euro-sign",
                          icon: "euro-sign"
                        })
                      ]),
                      _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$gettext("Reconversion")), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.account?.walletData)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      href: "#",
                      class: "dropdown-item is-flex",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.exportWallet()))
                    }, [
                      _createElementVNode("div", _hoisted_17, [
                        _createVNode(_component_fa_icon, {
                          class: "wallet",
                          icon: "wallet"
                        })
                      ]),
                      _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$gettext("Export wallet")), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ], 2)
        ], 2)
      ])
    ]),
    (
        _ctx.account?.subAccounts &&
        _ctx.account.subAccounts.length > 0 &&
        _ctx.showSubAccounts
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.account.subAccounts, (account) => {
            return (_openBlock(), _createBlock(_component_BankAccountItem, {
              isSub: true,
              "show-actions": false,
              class: "mt-4 subaccount",
              onAccountSelected: ($event: any) => (_ctx.$emit('accountSelected', account)),
              account: account
            }, {
              name: _withCtx(() => [
                _createTextVNode(_toDisplayString(account.name()), 1)
              ]),
              _: 2
            }, 1032, ["onAccountSelected", "account"]))
          }), 256))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}